<template>
	<section class="container">
		<div class="row">
			<div class="col-12">
				<h1>Login</h1>
			</div>
		</div>
		<b-jumbotron bg-variant="light">
			<div class="col-auto mx-auto p-0" v-if="emailSent">
				<center>
					<h2>
						<font-awesome-icon :icon="['fa', 'user-lock']" class="icon" />
						<br />
						2 vejs verifikation <br />
						<br />
						6 cifre engangskode er nu blevet sendt til din <b>email</b>.
					</h2>
					<br />
					<div style="display: flex; flex-direction: row; justify-content: center; align-items: center">
						<OtpInput
							ref="otpInput"
							input-classes="otp-input"
							separator="-"
							:num-inputs="6"
							:should-auto-focus="true"
							:is-input-num="true"
							@on-complete="onComplete"
						/>
					</div>
					<p>
						<br />
						<br />

						<i
							>Der kan opleves at email bliver ikke modtaget, i denne tilfælde kan du prøve at logge dig på igen, eller prøve med en anden
							browser. <br />Hvis du stadig ikke modtager email, så bedes du at kontakte supporten
						</i>
					</p>
				</center>
			</div>
			<div class="row" v-show="!emailSent">
				<b-form class="col-12" @submit.prevent="onSubmit">
					<b-form-group id="login">
						<div class="row">
							<div class="col-lg-6 col-12">
								<label> Email </label>
								<InputText v-model="email" :required="true" />
							</div>
							<div class="col-lg-6 col-12">
								<label> Password </label>
								<InputPassword v-model="password" :required="true" />
							</div>
						</div>
						<br />
						<BaseActionButton v-bind:clicked="clicked" v-bind:disabled="clicked" type="submit" id="login-button"> Login </BaseActionButton>
					</b-form-group>
				</b-form>
			</div>
			<div class="row" v-if="!emailSent">
				<div class="col-auto">
					<router-link to="/forgot">
						<b>Få ny adgangskode ved første login</b>
					</router-link>
				</div>
				<div class="col-auto ml-auto">
					<router-link to="/forgot">
						<b>Nulstil password</b>
					</router-link>
				</div>
			</div>
		</b-jumbotron>
	</section>
</template>
<script>
import firebaseApp from '@/plugins/firebase'
import { getAuth, signInWithCustomToken } from 'firebase/auth'
import InputText from '@/modules/global/atomComponents/InputText.vue'
import InputPassword from '@/modules/global/atomComponents/InputPassword.vue'
import config from '../../../../../conf.json'
import axios from 'axios'
import OtpInput from '@bachdgvn/vue-otp-input'

export default {
	name: 'Login',
	components: {
		InputText,
		InputPassword,
		OtpInput,
	},
	data() {
		return {
			clicked: false,
			password: '',
			email: '',
			emailSent: false,
			auth: null,
			uid: null,
			token: null,
			codeValue: [1],
		}
	},
	mounted() {
		this.$emit('updateLoader')
		console.log('mounted login')
	},
	methods: {
		async onComplete(otp) {
			try {
				// Axios call sign in with password and email to firebase auth identiy toolkit
				var options = {
					url: config.firebaseConfig.apiURL + '/auth/' + this.uid + '/validate/' + otp,
					method: 'POST',
					headers: {
						Authorization: 'Bearer ' + this.token,
					},
				}
				var result = await axios(options)
				var customToken = result.data
				var auth = getAuth()
				const LAST_LOGIN_KEY = 'lastLoginTime'
				const now = new Date().getTime()
				// Store or update the login timestamp
				localStorage.setItem(LAST_LOGIN_KEY, now)
				await signInWithCustomToken(auth, customToken)
			} catch (error) {
				console.log(error.message)
				this.toast('FEJL', error.message, false)
			}
		},
		async onSubmit() {
			try {
				this.clicked = true
				var email = this.email
				var password = this.password
				var apikey = config.firebaseConfig.apiKey
				// Axios call sign in with password and email to firebase auth identiy toolkit
				var options = {
					url: 'https://identitytoolkit.googleapis.com/v1/accounts:signInWithPassword?key=' + apikey,
					method: 'POST',
					data: {
						email: email,
						password: password,
						returnSecureToken: true,
					},
				}
				var result = await axios(options)

				this.uid = result.data.localId
				this.token = result.data.idToken

				var options = {
					url: config.firebaseConfig.apiURL + '/auth/' + this.uid + '/generateOtp',
					method: 'POST',
					headers: {
						Authorization: 'Bearer ' + this.token,
					},
				}
				var resultOtp = await axios(options)
				if (resultOtp.data.error) {
					this.toast('FEJL', resultOtp.data.error.message, false)
					this.clicked = false
					return
				}
				this.toast('Email sendt!', ' Tjek din indbakke for e-mailen fra vores kundeportal. Brug koden til at fuldføre din login.', true)
				this.emailSent = true
				this.clicked = false
			} catch (error) {
				console.log(error)
				this.clicked = false
				this.toast('ERROR', 'Noget gik galt - Brugernavn eller password er forkert', false)
			}
		},
	},
}
</script>
<style lang="scss">
.otp-input {
	width: 80px;
	height: 80px;
	padding: 5px;
	margin: 0 10px;
	font-size: 40px;
	border-radius: 4px;
	border: 1px solid rgba(0, 0, 0, 0.3);
	text-align: center;

	&.error {
		border: 1px solid red !important;
	}

	&::-webkit-inner-spin-button,
	&::-webkit-outer-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}
}
</style>
